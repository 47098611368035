import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { User, Website } from "../utils/types";

export const userAtom = atomWithStorage<User>(
  "user",
  {} as User,
  createJSONStorage<User>(() => sessionStorage)
);

export const accessTokenAtom = atomWithStorage<string>(
  "accessToken",
  "" as string,
  createJSONStorage<string>(() => sessionStorage)
);

export const websitesAtom = atomWithStorage<Website[]>(
  "websites",
  [] as Website[],
  createJSONStorage<Website[]>(() => sessionStorage)
);

export const selectedWebsiteAtom = atomWithStorage<Website | null>(
  "selectedWebsite",
  null as Website | null,
  createJSONStorage<Website | null>(() => sessionStorage)
);
