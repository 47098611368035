import React from 'react';
import { Box, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './styles/AverageScoringTile.css';

const data = [
  { date: '2023-04-01', score: 60 },
  { date: '2023-04-02', score: 72 },
  { date: '2023-04-03', score: 81 },
  { date: '2023-04-04', score: 77 },
  { date: '2023-04-05', score: 69 },
  { date: '2023-04-06', score: 71 },
  { date: '2023-04-07', score: 75 },
];

const AverageScoringTile: React.FC = () => {
    return (
        <Box className="average-scoring-tile">
            <Typography variant="h6" className="tile-title">Average Scoring</Typography>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 10,
                        bottom: 25,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                        dataKey="date" 
                        angle={-45}
                        textAnchor="end"
                        height={60}
                    />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="score" stroke="#A69ED4" name="Average Score" />
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default AverageScoringTile;