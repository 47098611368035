import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { accessTokenAtom } from "../state/atom";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosConfig";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);

  const isTokenExpired = (token: string) => {
    if (!token) return true;
    try {
      const decoded = jwtDecode(token) as { exp: number };
      return decoded.exp < Date.now() / 1000;
    } catch (e) {
      return true;
    }
  };

  useEffect(() => {
    const refreshAccessToken = async () => {
      try {
        const response = await axiosInstance.post(
          "token/refresh/",
          {},
          {
            withCredentials: true,
          }
        );
        if (response.status === 200 && response.data.access) {
          setAccessToken(response.data.access);
          return true;
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
      }
      return false;
    };

    const validateAuthentication = async () => {
      if (!accessToken || isTokenExpired(accessToken)) {
        const refreshed = await refreshAccessToken();
        if (!refreshed) {
          sessionStorage.clear();
          navigate("/login");
        }
      }
    };

    validateAuthentication();
  }, [accessToken, navigate, setAccessToken]);

  return <>{children}</>;
};

export default ProtectedRoute;
