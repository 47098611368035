import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";
import Navbar from "../Navbar";
import SemaphoreNavBar from "./SemaphoreNavBar";
import SemaphoreStatsBar from "./SemaphoreStatsBar";
import TrafficTile from "./TrafficTile";
import AverageScoringTile from "./AverageScoringTile";
import SiteEditor from "./SiteEditor";
import WarningPopup from "../WarningPopup";
import DeleteWebsitePopup from "./DeleteWebsitePopup";
import { useAtom } from "jotai";
import { selectedWebsiteAtom } from "../../state/atom";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#1E1E1E",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
});

const SemaphoreDashboard: React.FC = () => {
  const [siteEditorOpen, setSiteEditorOpen] = React.useState(false);
  const [siteDeleterOpen, setSiteDeleterOpen] = React.useState(false);
  const [editWarningOpen, setEditWarningOpen] = React.useState(false);
  const [mode, setMode] = React.useState<"create" | "edit">("create");
  const [selectedSite] = useAtom(selectedWebsiteAtom);

  const handleCreateSiteEditorOpen = () => {
    setMode("create");
    setSiteEditorOpen(true);
  };

  const handleEditSiteEditorOpen = () => {
    if (!selectedSite) {
      setEditWarningOpen(true);
      return;
    }
    setMode("edit");
    setSiteEditorOpen(true);
  };

  const handleSiteEditorClose = () => {
    setSiteEditorOpen(false);
  };

  const handleWarningClose = () => {
    setEditWarningOpen(false);
  };

  const handleDeleteClick = () => {
    if (!selectedSite) {
      setEditWarningOpen(true);
      return;
    }
    setSiteDeleterOpen(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <Navbar />
        <SemaphoreNavBar
          onAddClick={handleCreateSiteEditorOpen}
          onSettingsClick={handleEditSiteEditorOpen}
          onDeleteClick={handleDeleteClick}
        />
        <Box
          sx={{
            flexGrow: 1,
            px: { xs: 2, sm: 3 },
            py: 2,
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SemaphoreStatsBar />
            </Grid>
            <Grid item xs={12} md={6}>
              <TrafficTile />
            </Grid>
            <Grid item xs={12} md={6}>
              <AverageScoringTile />
            </Grid>
          </Grid>
        </Box>
        <SiteEditor
          open={siteEditorOpen}
          onClose={handleSiteEditorClose}
          mode={mode}
        />
        <WarningPopup
          isOpen={editWarningOpen}
          message={"Please select a website first."}
          onClose={handleWarningClose}
        />
        <DeleteWebsitePopup
          open={siteDeleterOpen}
          onClose={() => setSiteDeleterOpen(false)}
          websiteID={selectedSite?.id || 0}
        />
      </Box>
    </ThemeProvider>
  );
};

export default SemaphoreDashboard;
