import React from 'react';
import { Box, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import './styles/TrafficTile.css';

const data = [
  { date: '2023-04-01', total: 4000, passed: 2400, failed: 1600 },
  { date: '2023-04-02', total: 3000, passed: 1398, failed: 1602 },
  { date: '2023-04-03', total: 2000, passed: 9800, failed: 2200 },
  { date: '2023-04-04', total: 2780, passed: 3908, failed: 2000 },
  { date: '2023-04-05', total: 1890, passed: 4800, failed: 1500 },
  { date: '2023-04-06', total: 2390, passed: 3800, failed: 2300 },
  { date: '2023-04-07', total: 3490, passed: 4300, failed: 2100 },
];


const TrafficTile: React.FC = () => {
    return (
        <Box className="traffic-tile">
            <Typography variant="h6" className="tile-title">Traffic</Typography>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 10,
                        bottom: 25,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                         dataKey="date"
                         angle={-45}
                        textAnchor="end"
                        height={60}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend
                        iconType="square"
                        verticalAlign="bottom"
                        align="center"
                        layout="horizontal"
                        wrapperStyle={{paddingTop: 20}}
                    />
                    <Line type="monotone" dataKey="total" stroke="#FFFFFF" name="Total" />
                    <Line type="monotone" dataKey="passed" stroke="#89E92A" name="Passed" />
                    <Line type="monotone" dataKey="failed" stroke="#F23A71" name="Failed" />
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default TrafficTile;