import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import './styles/AppFooter.css';

const AppFooter: React.FC = () => {
    return (
        <Box className="app-footer">
            <Box className="footer-section">
                <Typography variant="body2" className="footer-text">
                    Proudly based in Pittsburgh, Pennsylvania
                </Typography>
                <Typography variant="body2" className="footer-text">
                    help@anybyte.ai
                </Typography>
            </Box>
            <Box className="footer-section">
                <Typography variant="body2" className="footer-text">
                    <Link href="/terms" color="inherit" className="footer-link">
                        Terms of Service
                    </Link>
                </Typography>
                <Typography variant="body2" className="footer-text">
                    <Link href="/privacy" color="inherit" className="footer-link">
                        Privacy Policy
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default AppFooter;
