import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  TextField,
  InputLabel,
  Button,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./styles/SiteEditor.css";
import axiosInstance from "../../axiosConfig";
import APIKeyModal from "./APIKeyModal";
import { EditIcon } from "lucide-react";
import { Website } from "../../utils/types";
import UnsavedChangesAlert from "../UnsavedChangesAlert";
import { useAtom } from "jotai";
import { selectedWebsiteAtom, websitesAtom } from "../../state/atom";
import SecuritySettings from "./SecuritySettings";

interface SiteEditorProps {
  open: boolean;
  onClose: () => void;
  mode: "create" | "edit";
}

const SiteEditor: React.FC<SiteEditorProps> = ({ open, onClose, mode }) => {
  const [selectedSite, setSelectedSite] = useAtom(selectedWebsiteAtom);
  const [websites, setWebsites] = useAtom(websitesAtom);
  const [websiteName, setWebsiteName] = useState(selectedSite?.name || "");
  const [websiteURL, setWebsiteURL] = useState(selectedSite?.url || "");
  const [websiteSecuritySettings, setWebsiteSecuritySettings] = useState(
    mode === "edit" ? selectedSite?.security_settings || 0 : 50
  );
  const [error, setError] = useState("");
  const [APIKey, setAPIKey] = useState("");
  const [keyShown, setKeyShown] = useState(false);
  const [editingWebsiteName, setEditingWebsiteName] = useState(false);
  const [editingWebsiteURL, setEditingWebsiteURL] = useState(false);
  const [unsavedChangesAlertOpen, setUnsavedChangesAlertOpen] = useState(false);

  useEffect(() => {
    if (mode === "edit") {
      setWebsiteName(selectedSite?.name || "");
      setWebsiteURL(selectedSite?.url || "");
      setWebsiteSecuritySettings(selectedSite?.security_settings || 0);
    } else {
      setWebsiteName("");
      setWebsiteURL("");
      setWebsiteSecuritySettings(50);
    }
  }, [selectedSite, mode]);

  const handleCreateAPIKeyModal = (apiKey: string) => {
    setAPIKey(apiKey);
    setKeyShown(true);
  };

  const handleAPIKeyModalClose = () => {
    setKeyShown(false);
  };

  const handleSiteEditorClose = () => {
    if (mode === "create") {
      setWebsiteName("");
      setWebsiteURL("");
      setError("");
    }
    if (mode === "edit" && isEditing()) {
      setUnsavedChangesAlertOpen(true);
      return;
    }
    setEditingWebsiteName(false);
    setEditingWebsiteURL(false);
    onClose();
  };

  const handleSiteEditorSaveAndClose = () => {
    onClose();
    if (mode === "create") {
      setWebsiteName("");
      setWebsiteURL("");
      setError("");
    }
    setEditingWebsiteName(false);
    setEditingWebsiteURL(false);
  };

  const isEditing = () => {
    return (
      editingWebsiteName ||
      editingWebsiteURL ||
      websiteSecuritySettings !== selectedSite?.security_settings
    );
  };

  const handleUnsavedChangesAlertClose = () => {
    setUnsavedChangesAlertOpen(false);
  };

  const handleUnsavedChangesAlertDiscard = () => {
    setUnsavedChangesAlertOpen(false);
    setEditingWebsiteName(false);
    setEditingWebsiteURL(false);
    onClose();
  };

  const handleUnsavedChangesAlertContinue = () => {
    setUnsavedChangesAlertOpen(false);
  };

  const renderEditableField = (
    label: string,
    value: string,
    onChange: (value: string) => void,
    editing: boolean,
    setEditing: (editing: boolean) => void,
    type: string = "text"
  ) => (
    <>
      <InputLabel
        htmlFor={label.toLowerCase()}
        className="profile-editor-label"
      >
        {label}
      </InputLabel>
      <TextField
        id={label.toLowerCase()}
        fullWidth
        variant="outlined"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="site-editor-input"
        type={type}
        disabled={!editing}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={() => setEditing(!editing)}>
                <EditIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );

  const handleSiteEditorSubmit = () => {
    if (mode === "create") {
      axiosInstance
        .post("/websites/", {
          name: websiteName,
          url: websiteURL,
        })
        .then((res) => {
          const apiKey = res.data.api_key;
          const newSite = {
            id: res.data.id,
            name: websiteName,
            url: websiteURL,
            security_settings: websiteSecuritySettings,
          };
          websites.push(newSite);
          setWebsites(websites);
          setSelectedSite(newSite);
          handleSiteEditorClose();
          handleCreateAPIKeyModal(apiKey);
        })
        .catch((error) => {
          if (error.request.responseText.includes("URL")) {
            setError(
              "Invalid URL.  Please make sure it is formatted correctly.  \n (e.g. https://www.example.com)"
            );
          } else {
            setError("An error occurred. Please try again later.");
          }
        });
    } else {
      axiosInstance
        .put(`/websites/${selectedSite?.id}/`, {
          name: websiteName,
          url: websiteURL,
        })
        .then(() => {
          let updatedSite: Website = {
            id: selectedSite?.id || 0,
            name: websiteName,
            url: websiteURL,
            security_settings: websiteSecuritySettings,
          };
          setSelectedSite(updatedSite);
          let updatedWebsites = websites.filter((website: Website) => {
            return website.id !== selectedSite?.id;
          });
          updatedWebsites.push(updatedSite);
          setWebsites(updatedWebsites);
          handleSiteEditorSaveAndClose();
        })
        .catch((error) => {
          setError(error);
        });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleSiteEditorClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          className: "site-editor-dialog",
        }}
      >
        <DialogTitle>
          <Box className="site-editor-title">
            <Typography
              variant="h6"
              component="div"
              className="site-editor-heading"
            >
              {mode === "create" ? "Create Website" : "Edit Website"}
            </Typography>
            <IconButton
              onClick={handleSiteEditorClose}
              size="small"
              className="site-editor-close-button"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        {error && (
          <div className="profile-error">
            <i className="error-icon">&#9888;</i>
            <p>{error}</p>
          </div>
        )}
        <DialogContent>
          <Box className="site-editor-content">
            {mode === "create" ? (
              <>
                <InputLabel
                  htmlFor="website-name"
                  className="site-editor-label"
                >
                  Name
                </InputLabel>
                <TextField
                  id="website-name"
                  fullWidth
                  variant="outlined"
                  value={websiteName}
                  onChange={(e) => setWebsiteName(e.target.value)}
                  placeholder="Enter website name here"
                  className="site-editor-input"
                />
              </>
            ) : (
              <>
                {renderEditableField(
                  "Website Name",
                  websiteName,
                  setWebsiteName,
                  editingWebsiteName,
                  setEditingWebsiteName
                )}
              </>
            )}
            {mode === "create" ? (
              <>
                <InputLabel htmlFor="website-url" className="site-editor-label">
                  Website URL
                </InputLabel>
                <TextField
                  id="website-url"
                  fullWidth
                  variant="outlined"
                  value={websiteURL}
                  onChange={(e) => setWebsiteURL(e.target.value)}
                  placeholder="Enter Website URL here"
                  className="site-editor-input"
                />
              </>
            ) : (
              <>
                {renderEditableField(
                  "Website URL",
                  websiteURL,
                  setWebsiteURL,
                  editingWebsiteURL,
                  setEditingWebsiteURL
                )}
              </>
            )}
            <SecuritySettings
              mode={mode}
              onChange={setWebsiteSecuritySettings}
            />
            <Box className="button-container">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSiteEditorSubmit}
                className="site-editor-next-button"
              >
                {mode === "create" ? "Next" : "Save"}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <APIKeyModal
        apiKey={APIKey}
        isOpen={keyShown}
        onClose={handleAPIKeyModalClose}
      />
      <UnsavedChangesAlert
        open={unsavedChangesAlertOpen}
        onClose={handleUnsavedChangesAlertClose}
        onDiscard={handleUnsavedChangesAlertDiscard}
        onContinue={handleUnsavedChangesAlertContinue}
      />
    </>
  );
};

export default SiteEditor;
