import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import GetAppIcon from "@mui/icons-material/GetApp";
import "./styles/SemaphoreNavBar.css";
import { Website } from "../../utils/types";
import { TrashIcon } from "lucide-react";
import { useAtom } from "jotai";
import { selectedWebsiteAtom, websitesAtom } from "../../state/atom";

interface SemaphoreNavBarProps {
  onAddClick: () => void;
  onSettingsClick: () => void;
  onDeleteClick: () => void;
}

const SemaphoreNavBar: React.FC<SemaphoreNavBarProps> = ({
  onAddClick,
  onSettingsClick,
  onDeleteClick,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [websites] = useAtom(websitesAtom);
  const [selectedSite, setSelectedSite] = useAtom(selectedWebsiteAtom);

  useEffect(() => {
    if (selectedSite) {
      setSelectedOption(JSON.stringify(selectedSite));
    } else {
      setSelectedOption("");
    }
  }, [websites, selectedSite, selectedOption]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value as string);
    const website: Website = JSON.parse(event.target.value as string);
    setSelectedSite(website);
  };

  const compareWebsites = (a: Website, b: Website) => {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <AppBar position="static" className="semaphore-navbar">
      <Toolbar>
        <Select
          value={selectedOption}
          onChange={handleChange}
          className="dropdown-select"
          displayEmpty
          renderValue={(selected) => {
            if (websites.length === 0) {
              return (
                <Typography color="text.secondary">No websites</Typography>
              );
            }
            if (selected) {
              const website: Website = JSON.parse(selected as string);
              return website.name;
            }
            return (
              <Typography color="text.secondary">Select a website</Typography>
            );
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {websites.length === 0 ? (
            <MenuItem disabled>No websites available</MenuItem>
          ) : (
            websites.sort(compareWebsites).map((website: Website) => (
              <MenuItem key={website.name} value={JSON.stringify(website)}>
                {website.name}
              </MenuItem>
            ))
          )}
        </Select>
        <Box sx={{ flexGrow: 1 }} />
        <Button className="icon-button" onClick={onAddClick}>
          <AddIcon />
        </Button>
        <Button className="icon-button" onClick={onSettingsClick}>
          <SettingsIcon />
        </Button>
        <Button className="icon-button">
          <GetAppIcon />
        </Button>
        <Button className="icon-button" onClick={onDeleteClick}>
          <TrashIcon />
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default SemaphoreNavBar;
