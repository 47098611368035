import React, { useState } from "react";
import { Box, Slider, Typography } from "@mui/material";
import "./styles/MonetizationSettings.css";
import { useAtom } from "jotai";
import { selectedWebsiteAtom } from "../../state/atom";

const settings = [
  {
    value: 0,
    label: "Low",
    description: "Lower security, minimal user friction, reduced monetization",
  },
  {
    value: 50,
    label: "Medium",
    description:
      "Balanced security, moderate user friction, standard monetization",
  },
  {
    value: 100,
    label: "High",
    description:
      "Enhanced security, higher user friction, increased monetization",
  },
];

interface SecuritySettingsProps {
  mode: "create" | "edit";
  onChange: (value: number) => void;
}

const SecuritySettings: React.FC<SecuritySettingsProps> = ({
  mode,
  onChange,
}) => {
  const [selectedSite] = useAtom(selectedWebsiteAtom);
  const [value, setValue] = useState<number>(
    mode === "edit" ? selectedSite?.security_settings || 0 : 50
  );

  const handleChange = (event: Event, newValue: number | number[]) => {
    onChange(newValue as number);
    setValue(newValue as number);
  };

  const getSettingDescription = (value: number) => {
    if (value <= 25) return settings[0].description;
    if (value <= 75) return settings[1].description;
    return settings[2].description;
  };

  return (
    <Box className="monetization-settings">
      <Typography variant="h6" gutterBottom>
        Monetization Settings
      </Typography>
      <Typography variant="body2" className="settings-description">
        When a determination for a user can't be made, Semaphore displays an
        additional puzzle for the user to complete. While this puzzle provides
        additional information for security purposes, Anybyte also sells the
        collected data and passes on part of the profit to you, the website
        owner.
      </Typography>
      <Typography variant="body2" className="settings-description">
        Websites are able to customize the frequency at which these puzzles
        appear, where higher frequencies will lead to enhanced security and
        monetization but higher user friction.
      </Typography>
      <Box className="slider-container">
        <Slider
          className="custom-slider"
          aria-label="Monetization Settings"
          value={value}
          onChange={handleChange}
          step={null}
          marks={settings}
          min={0}
          max={100}
        />
      </Box>
      <Typography variant="body2" className="setting-description">
        {getSettingDescription(value)}
      </Typography>
    </Box>
  );
};

export default SecuritySettings;
