import React, { useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import axiosInstance from "../../axiosConfig";
import GoogleLoginButton from "../GoogleLogin";
import "./styles/Register.css";

const Register: React.FC = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validateInputs = () => {
    setError("");

    // Check for empty fields
    if (!username || !email || !confirmEmail || !password || !confirmPassword) {
      setError("Please fill in all fields");
      return false;
    }

    // Email match check
    if (email !== confirmEmail) {
      setError("Emails do not match");
      return false;
    }

    // Password match check
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return false;
    }

    // Password complexity checks
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[@$!%*?&]/.test(password);

    if (password.length < minLength) {
      setError(`Password must be at least ${minLength} characters long`);
      return false;
    } else if (!hasUpperCase) {
      setError("Password must contain an uppercase letter");
      return false;
    } else if (!hasLowerCase) {
      setError("Password must contain a lowercase letter");
      return false;
    } else if (!hasNumbers) {
      setError("Password must contain at least one number");
      return false;
    } else if (!hasSpecialChar) {
      setError("Password must contain one of (@$!%*?&)");
      return false;
    }

    return true;
  };

  const handleGoogleLoginError = () => {
    setError("Error loggin in with Google. Please try again.");
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateInputs()) {
      setIsLoading(true);
      axiosInstance
        .post("users/", {
          username: username,
          password: password,
          email: email,
          account_type: "ENT",
        })
        .then(() => {
          window.location.href = "/login";
        })
        .catch((error) => {
          if (
            error.response?.status === 400 &&
            error.response?.data?.username
          ) {
            setError("Username is already taken");
          } else if (
            error.response?.status === 400 &&
            error.response?.data?.email
          ) {
            setError("Email is already used by another account");
          } else {
            setError(
              error.response?.data || "An error occurred. Please try again."
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="register-page-wrapper">
      <div className="register-page-container">
        <div className="logo">
          <img
            src="logo_color_white.png"
            alt="logo"
            style={{ width: "50px" }}
          />
        </div>
        <h2 className="register-title">Register</h2>
        <form onSubmit={handleSubmit} noValidate>
          {error && (
            <div className="register-error">
              <i className="error-icon">&#9888;</i>
              <p>{error}</p>
            </div>
          )}
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Confirm Email"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            required
          />
          <div className="register-password-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="login-toggle-password"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
          <div className="register-password-container">
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="login-toggle-password"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
          <button
            type="submit"
            className={`register-submit-button ${isLoading ? "loading" : ""}`}
            disabled={isLoading}
          >
            {isLoading ? "" : "GO"}
          </button>
          <div className="footer-content">
            <div>Already have an account?</div>
            <div>
              <a href="/login">Sign in</a>
            </div>
          </div>
          <div className="line-wrapper">
            <div className="line"></div>
            <span className="line-text">or</span>
            <div className="line"></div>
          </div>
          <GoogleLoginButton
            onError={handleGoogleLoginError}
            text={"signup_with"}
          />
        </form>
      </div>
    </div>
  );
};

export default Register;
