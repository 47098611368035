import React, { useState } from "react";
import { CheckCircle, Eye, EyeOff } from "lucide-react";
import "./styles/ResetPassword.css";
import axiosInstance from "../../axiosConfig";

const ResetPassword: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    setIsLoading(true);
    axiosInstance
      .post("auth/reset-password/", {
        uid: new URLSearchParams(window.location.search).get("uid") || "",
        token: new URLSearchParams(window.location.search).get("token") || "",
        new_password: newPassword,
      })
      .then(() => {
        setError("");
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.error(error);
        setError("An error occurred. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const togglePasswordVisibility = (field: "password" | "confirm") => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <div className="reset-password-page-wrapper">
      <div className="reset-password-page-container">
        <div className="logo">
          <img src="/logo_color_white.png" alt="Logo" />
        </div>
        <h2 className="reset-password-title">
          {isSubmitted ? "Back to Login" : "Reset Password"}
        </h2>
        {!isSubmitted ? (
          <form onSubmit={handleSubmit}>
            <p className="reset-password-description">
              Enter and confirm your new password.
            </p>
            {error && (
              <div className="profile-error">
                <i className="error-icon">&#9888;</i>
                <p>{error}</p>
              </div>
            )}
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
                required
              />
              <button
                type="button"
                className="toggle-password"
                onClick={() => togglePasswordVisibility("password")}
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
            <div className="password-input-container">
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm New Password"
                required
              />
              <button
                type="button"
                className="toggle-password"
                onClick={() => togglePasswordVisibility("confirm")}
              >
                {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
            <button
              type="submit"
              className="reset-password-submit-button"
              disabled={isLoading}
            >
              {isLoading ? <div className="loading-spinner"></div> : "Confirm"}
            </button>
          </form>
        ) : (
          <div className="success-message">
            <CheckCircle className="check-icon" />
            <p>Your password has been reset!</p>
            <div className="back-to-login">
              <a href="/login">Back to login</a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
