import React, { useState } from "react";
import { CheckCircle } from "lucide-react";
import "./styles/ForgotPassword.css";
import axiosInstance from "../../axiosConfig";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance
      .post("auth/forgot-password/", {
        email: email,
      })
      .then(() => {
        setError("");
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.error(error);
        setError("An error occurred. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="forgot-password-page-wrapper">
      <div className="forgot-password-page-container">
        <div className="logo">
          <img src="/logo_color_white.png" alt="Logo" />
        </div>
        <h2 className="forgot-password-title">
          {isSubmitted ? "Check Your Email" : "Forgot Password?"}
        </h2>
        {!isSubmitted ? (
          <form onSubmit={handleSubmit} noValidate>
            <p className="forgot-password-description">
              Enter the email address you used to sign up and we'll send you a
              link to reset your password.
            </p>
            {error && (
              <div className="profile-error">
                <i className="error-icon">&#9888;</i>
                <p>{error}</p>
              </div>
            )}
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              type="submit"
              className="forgot-password-submit-button"
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="loading-spinner"></div>
              ) : (
                "Reset Password"
              )}
            </button>
            <div className="sign-up-link">
              Don't have an account? <a href="/register">Sign up</a>
            </div>
          </form>
        ) : (
          <div className="success-message">
            <CheckCircle className="check-icon" />
            <p>
              A password reset link has been sent to your email. The link will
              be active for 24 hours.
            </p>
            <div className="back-to-login">
              <a href="/login">Back to login</a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
