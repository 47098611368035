import React, { useState } from "react";
import { User, Website } from "../../utils/types";
import axiosInstance from "../../axiosConfig";
import GoogleLoginButton from "../GoogleLogin";
import { Eye, EyeOff } from "lucide-react";
import "./styles/Login.css";
import { useSetAtom } from "jotai";
import { accessTokenAtom, userAtom, websitesAtom } from "../../state/atom";
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const setAccessToken = useSetAtom(accessTokenAtom);
  const setUser = useSetAtom(userAtom);
  const setWebsites = useSetAtom(websitesAtom);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const validateInputs = () => {
    setError("");

    if (username === "" || password === "") {
      setError("Please fill in all fields");
      return false;
    }

    return true;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateInputs()) return;

    setIsLoading(true);

    axiosInstance
      .post("login/", {
        username: username,
        password: password,
      })
      .then((res) => {
        setAccessToken(res.data.access);
        let user: User = {
          id: res.data.id,
          username: res.data.username,
          email: res.data.email,
          profile_picture: res.data.profile_image,
        };
        setUser(user);
        let websites = res.data.websites.map((website: any) => {
          return {
            id: website.id,
            name: website.name,
            url: website.url,
            security_settings: website.security_settings,
          } as Website;
        });
        setWebsites(websites);
        navigate("/semaphore");
      })
      .catch((error) => {
        setError(
          error.response?.status === 401
            ? "Invalid username or password"
            : "An error occurred. Please try again."
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGoogleLoginClick = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent form submission
  };

  const handleGoogleLoginError = () => {
    setError("Error logging in with Google. Please try again.");
  };

  return (
    <div className="login-page-wrapper">
      <div className="login-page-container">
        <div className="logo">
          <img
            src="/logo_color_white.png"
            alt="Logo"
            style={{ width: "50px" }}
          />
        </div>
        <h2 className="login-title">Welcome Back!</h2>
        <form onSubmit={handleSubmit} noValidate>
          {error && (
            <div id="login-error" className="login-error">
              <i className="error-icon">&#9888;</i>
              <p>{error}</p>
            </div>
          )}
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <div className="login-password-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="login-toggle-password"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
          <div className="forgot-password-container">
            <a className="forgot-password-link" href="/forgot-password">
              Forgot password?
            </a>
          </div>
          <button
            type="submit"
            className={`login-submit-button ${isLoading ? "loading" : ""}`}
            disabled={isLoading}
          >
            {isLoading ? "" : "GO"}
          </button>
          <div className="sign-up-link">
            Don't have an account? <a href="/register">Sign up</a>
          </div>
          <div className="line-wrapper">
            <div className="line"></div>
            <span className="line-text">or</span>
            <div className="line"></div>
          </div>
          <div onClick={handleGoogleLoginClick}>
            <GoogleLoginButton onError={handleGoogleLoginError} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
