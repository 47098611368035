import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  IconButton,
  SelectChangeEvent,
  Button,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/SemaphoreStatsBar.css";
import { Refresh } from "@mui/icons-material";

const SemaphoreStatsBar: React.FC = () => {
  const [dateRange, setDateRange] = useState("3");
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const datePickerRef = useRef<HTMLDivElement>(null);

  const handleDateRangeChange = (event: SelectChangeEvent) => {
    setDateRange(event.target.value);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const formatDate = (date: Date): string => {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <Box className="semaphore-stats-bar-container">
      <Box className="semaphore-stats-bar">
        <Box className="stats-group">
          <Box className="stat-item">
            <Typography variant="body2" className="stat-label">
              Total Requests
            </Typography>
            <Typography variant="h4" className="stat-value">
              45,273
            </Typography>
          </Box>
          <Box className="stat-item">
            <Typography variant="body2" className="stat-label">
              Suspicious Requests
            </Typography>
            <Typography variant="h4" className="stat-value">
              6.9%
            </Typography>
          </Box>
          <Box className="stat-item">
            <Typography variant="body2" className="stat-label">
              Earnings
            </Typography>
            <Typography variant="h4" className="stat-value">
              $230.61
            </Typography>
          </Box>
        </Box>
        <Box className="date-controls">
          <Button className="refresh-button">
            <Refresh className="refresh-icon" />
          </Button>
          <Select
            value={dateRange}
            onChange={handleDateRangeChange}
            className="date-range-select"
            MenuProps={{ classes: { paper: "date-range-menu" } }}
          >
            <MenuItem value="1">Past 24 hours</MenuItem>
            <MenuItem value="3">Past 3 days</MenuItem>
            <MenuItem value="7">Past week</MenuItem>
            <MenuItem value="30">Past month</MenuItem>
          </Select>
          <div ref={datePickerRef} className="date-picker-wrapper">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              customInput={
                <IconButton className="calendar-button">
                  <CalendarTodayIcon />
                </IconButton>
              }
              popperContainer={({ children }) => (
                <div className="date-picker-popper-container">{children}</div>
              )}
              popperPlacement="bottom-end"
            />
          </div>
          {selectedDate && (
            <Typography variant="body2" className="selected-date">
              {formatDate(selectedDate)}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SemaphoreStatsBar;
