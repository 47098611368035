import React from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import ProfileIcon from "./ProfileIcon";
import { useLocation } from "react-router-dom";
import "./styles/Navbar.css";

const Navbar: React.FC = () => {
  const location = useLocation();

  const isSelected = (path: string) => location.pathname === path;

  return (
    <AppBar position="static">
      <Toolbar>
        <Button
          component="a"
          href="https://www.anybyte.ai"
          className="navbar-logo-button"
        >
          <img src="logo_color_white.png" alt="logo" className="navbar-logo" />
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Box className="navbar-links">
          <Button
            component="a"
            href="/gateway"
            className={`navbar-button ${
              isSelected("/gateway")
                ? "navbar-button-selected"
                : "navbar-button-unselected"
            }`}
          >
            Gateway
          </Button>
          <Button
            component="a"
            href="/semaphore"
            className={`navbar-button ${
              isSelected("/semaphore")
                ? "navbar-button-selected"
                : "navbar-button-unselected"
            }`}
          >
            Semaphore
          </Button>
          <Button
            component="a"
            href="/api-reference"
            className={`navbar-button ${
              isSelected("/api-reference")
                ? "navbar-button-selected"
                : "navbar-button-unselected"
            }`}
          >
            API Reference
          </Button>
        </Box>
        <ProfileIcon />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
