import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles'; 
import Navbar from './Navbar';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
    text: {
      primary: '#FFFFFF',
    },
  },
});

const Gateway = () => {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
    </ThemeProvider>
  );
};

export default Gateway;