import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axiosInstance from "../axiosConfig";
import { User, Website } from "../utils/types";
import { useSetAtom } from "jotai";
import { accessTokenAtom, userAtom, websitesAtom } from "../state/atom";
import { useNavigate } from "react-router-dom";

interface GoogleLoginButtonProps {
  onError: () => void;
  text?: "signin_with" | "signup_with" | "continue_with" | "signin";
}

function GoogleLoginButton({ onError, text }: GoogleLoginButtonProps) {
  const navigate = useNavigate();
  const setAccessToken = useSetAtom(accessTokenAtom);
  const setUser = useSetAtom(userAtom);
  const setWebsites = useSetAtom(websitesAtom);
  const responseMessage = (response: any) => {
    axiosInstance
      .post("/google-login/", {
        token: response.credential,
      })
      .then((response) => {
        setAccessToken(response.data.access);
        let user: User = {
          id: response.data.id,
          username: response.data.username,
          email: response.data.email,
          profile_picture: response.data.profile_image,
        };
        setUser(user);
        let websites = response.data.websites.map((website: Website) => {
          return {
            id: website.id,
            name: website.name,
            url: website.url,
            security_settings: website.security_settings,
          } as Website;
        });
        setWebsites(websites);
        navigate("/semaphore");
      })
      .catch(() => {
        onError();
      });
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <GoogleLogin
        onSuccess={responseMessage}
        onError={onError}
        theme={"filled_black"}
        logo_alignment={"left"}
        shape={"rectangular"}
        text={text}
        size={"large"}
        width={340}
      ></GoogleLogin>
    </GoogleOAuthProvider>
  );
}

export default GoogleLoginButton;
