import React, { useState, useRef, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  TextField,
  InputLabel,
  Button,
  Avatar,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { EditIcon } from "lucide-react";
import PersonIcon from "@mui/icons-material/Person";
import "./styles/ProfileEditor.css";
import axiosInstance from "../axiosConfig";
import UnsavedChangesAlert from "./UnsavedChangesAlert";
import { useAtom } from "jotai";
import { userAtom } from "../state/atom";

interface ProfileEditorProps {
  open: boolean;
  onClose: () => void;
  currentUsername: string;
  currentEmail: string;
  currentProfilePicture: string;
}

const ProfileEditor: React.FC<ProfileEditorProps> = ({
  open,
  onClose,
  currentUsername,
  currentEmail,
  currentProfilePicture,
}) => {
  const [user, setUser] = useAtom(userAtom);

  const [username, setUsername] = useState(currentUsername);
  const [email, setEmail] = useState(currentEmail);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profilePictureData, setProfilePictureData] = useState(
    currentProfilePicture
  );
  const [error, setError] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [editingUsername, setEditingUsername] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [editingPassword, setEditingPassword] = useState(false);
  const [editingProfilePicture, setEditingProfilePicture] = useState(false);

  const [showUnsavedAlert, setShowUnsavedAlert] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    if (
      editingUsername ||
      editingEmail ||
      editingPassword ||
      editingProfilePicture
    ) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [editingUsername, editingEmail, editingPassword, editingProfilePicture]);

  const handleClose = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedAlert(true);
    } else {
      resetAndClose();
    }
  };

  const handleSaveAndClose = () => {
    onClose();
    setError("");
    setUsername(username);
    setEmail(email);
    setProfilePictureData(profilePictureData);
    setPassword("");
    setEditingUsername(false);
    setEditingEmail(false);
    setEditingPassword(false);
    setEditingProfilePicture(false);
  };

  const resetAndClose = () => {
    onClose();
    setError("");
    setUsername(currentUsername);
    setEmail(currentEmail);
    setProfilePictureData(currentProfilePicture);
    setPassword("");
    setEditingUsername(false);
    setEditingEmail(false);
    setEditingPassword(false);
    setEditingProfilePicture(false);
  };

  const handleDiscard = () => {
    setShowUnsavedAlert(false);
    resetAndClose();
  };

  const handleContinueEditing = () => {
    setShowUnsavedAlert(false);
  };

  const handleProfilePictureUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      // Check file type
      if (!file.type.startsWith("image/")) {
        setError("Please select an image file");
        return;
      }

      // Check file size (e.g., limit to 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        setError("File is too large. Please select an image smaller than 5MB.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setProfilePictureData(base64String);
        setEditingProfilePicture(true);
        setError(""); // Clear any previous errors
      };

      reader.onerror = () => {
        console.error("Error reading file");
        setError(
          "An error occurred while processing the image. Please try again."
        );
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    if (!username || !email) {
      setError("Username and email are required.");
      return;
    }
    if (editingPassword && password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    const updateData: {
      username: string;
      email: string;
      password?: string;
      profile_image?: string;
    } = { username: username, email: email };
    if (password) {
      updateData.password = password;
    }
    if (profilePictureData !== user.profile_picture) {
      updateData.profile_image = profilePictureData;
    }
    axiosInstance
      .patch(`users/${user.id}/`, updateData)
      .then(() => {
        setUser({
          ...user,
          username: username,
          email: email,
          profile_picture: profilePictureData,
        });
        handleSaveAndClose();
      })
      .catch((error) => {
        if (error.request.responseText.includes("username")) {
          setError("Username is already taken.");
        } else if (error.request.responseText.includes("email")) {
          setError("Email is already taken.");
        } else {
          setError("Failed to update profile. Please try again.");
        }
      });
  };

  const renderEditableField = (
    label: string,
    value: string,
    onChange: (value: string) => void,
    editing: boolean,
    setEditing: (editing: boolean) => void,
    type: string = "text"
  ) => (
    <>
      <InputLabel
        htmlFor={label.toLowerCase()}
        className="profile-editor-label"
      >
        {label}
      </InputLabel>
      <TextField
        id={label.toLowerCase()}
        fullWidth
        variant="outlined"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="profile-editor-input"
        type={type}
        disabled={!editing}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={() => setEditing(!editing)}>
                <EditIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          className: "profile-editor-dialog",
        }}
      >
        <DialogTitle>
          <Box className="profile-editor-title">
            <Typography
              variant="h6"
              component="div"
              className="profile-editor-heading"
            >
              Edit Profile
            </Typography>
            <IconButton
              onClick={handleClose}
              size="small"
              className="profile-editor-close-button"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box className="profile-editor-content">
            <Box className="profile-picture-container">
              <Avatar
                src={profilePictureData}
                alt={username}
                className="profile-picture"
              >
                {!profilePictureData && <PersonIcon sx={{ fontSize: 64 }} />}
              </Avatar>
              <IconButton
                className="edit-profile-picture-button"
                onClick={() => fileInputRef.current?.click()}
              >
                <EditIcon />
              </IconButton>
              <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={handleProfilePictureUpload}
                accept="image/*"
              />
            </Box>

            {error && (
              <div className="profile-error">
                <i className="error-icon">&#9888;</i>
                <p>{error}</p>
              </div>
            )}

            {renderEditableField(
              "Username",
              username,
              setUsername,
              editingUsername,
              setEditingUsername
            )}
            {renderEditableField(
              "Email",
              email,
              setEmail,
              editingEmail,
              setEditingEmail
            )}
            {renderEditableField(
              "New Password",
              password,
              setPassword,
              editingPassword,
              setEditingPassword,
              "password"
            )}

            {editingPassword && (
              <>
                <InputLabel
                  htmlFor="confirm-password"
                  className="profile-editor-label"
                >
                  Confirm New Password
                </InputLabel>
                <TextField
                  id="confirm-password"
                  fullWidth
                  variant="outlined"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="profile-editor-input"
                />
              </>
            )}

            <Box className="button-container">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                className="profile-editor-save-button"
              >
                Save Changes
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <UnsavedChangesAlert
        open={showUnsavedAlert}
        onClose={() => setShowUnsavedAlert(false)}
        onDiscard={handleDiscard}
        onContinue={handleContinueEditing}
      />
    </>
  );
};

export default ProfileEditor;
