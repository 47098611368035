import React, { useState } from "react";
import { Avatar, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Person } from "@mui/icons-material";
import "./styles/ProfileIcon.css";
import ProfileEditor from "./ProfileEditor";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../state/atom";

export default function ProfileIcon() {
  const navigate = useNavigate();
  const [user] = useAtom(userAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editorOpen, setEditorOpen] = useState(false);

  const getProfilePicture = () => {
    const profilePicture = user.profile_picture;
    if (profilePicture && profilePicture.includes("data:image/jpeg;base64,")) {
      return profilePicture;
    }
    if (profilePicture && profilePicture.includes("data:image/png;base64,")) {
      return profilePicture;
    }
    return "data:image/jpeg;base64," + profilePicture;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileSettingsOpen = () => {
    handleMenuClose();
    setEditorOpen(true);
  };

  const handleProfileEditorClose = () => {
    setEditorOpen(false);
  };

  return (
    <>
      <IconButton
        sx={{
          position: "absolute",
          top: 4,
          right: 8,
        }}
        onClick={handleClick}
      >
        <Avatar src={getProfilePicture()} alt="Profile Picture">
          <Person />
        </Avatar>
      </IconButton>
      <Menu
        className="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            sx: {
              width: "250px",
              maxWidth: "100%",
            },
          },
        }}
      >
        {user.email === user.username ? (
          <Typography className="profile-email">{user.email}</Typography>
        ) : (
          <Typography className="profile-username">
            {user.username + " — " + user.email}
          </Typography>
        )}
        <MenuItem className="menu-item" onClick={handleProfileSettingsOpen}>
          Profile
        </MenuItem>
        <MenuItem className="menu-item" onClick={handleClose}>
          Logout
        </MenuItem>
      </Menu>
      <ProfileEditor
        open={editorOpen}
        onClose={handleProfileEditorClose}
        currentUsername={user.username}
        currentEmail={user.email}
        currentProfilePicture={getProfilePicture()}
      />
    </>
  );
}
