import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { getDefaultStore } from "jotai";
import { accessTokenAtom } from "./state/atom";

interface RetryConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

interface RefreshTokenResponse {
  access: string;
}

export const refreshAccessToken = async (): Promise<string | null> => {
  try {
    const response = await axiosInstance.post<RefreshTokenResponse>(
      "token/refresh/",
      {},
      {
        withCredentials: true,
      }
    );
    if (response.status === 200 && response.data.access) {
      return response.data.access;
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
  }
  return null;
};

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_ENVIRONMENT === "dev"
      ? "http://localhost:8000/api/v1/"
      : "https://platform.anybyte.ai/api/v1/",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

function getCSRFToken() {
  const name = "csrftoken";
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getDefaultStore().get(accessTokenAtom);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["X-CSRFToken"] = getCSRFToken();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config as RetryConfig;
    if (
      error.response?.status === 401 &&
      originalRequest &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshAccessToken();
        if (newAccessToken) {
          getDefaultStore().set(accessTokenAtom, newAccessToken);
          if (axiosInstance.defaults.headers.common) {
            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${newAccessToken}`;
          }
          if (originalRequest.headers) {
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${newAccessToken}`;
          }
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        console.error("Error refreshing token:", refreshError);
      }
    }
    sessionStorage.clear();
    if (
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/register"
    ) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
