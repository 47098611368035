import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Eye, EyeOff, Copy } from "lucide-react";
import "./styles/APIKeyModal.css";

interface APIKeyModalProps {
  apiKey: string;
  isOpen: boolean;
  onClose: () => void;
}

const APIKeyModal: React.FC<APIKeyModalProps> = ({
  apiKey,
  isOpen,
  onClose,
}) => {
  const [showFullKey, setShowFullKey] = useState(false);
  const [copied, setCopied] = useState(false);

  const toggleShowFullKey = () => {
    setShowFullKey(!showFullKey);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiKey);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const maskedKey = `${apiKey.slice(0, 4)}...${apiKey.slice(-4)}`;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        className: "api-key-modal-dialog",
      }}
    >
      <DialogTitle>
        <Box className="api-key-modal-title">
          <Typography
            variant="h6"
            component="div"
            className="api-key-modal-heading"
          >
            Your API Key
          </Typography>
          <IconButton
            onClick={onClose}
            size="small"
            className="api-key-modal-close-button"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <Typography className="api-key-modal-subheading">
            This is the only time you will see this key. Please store it
            securely. You will need it to embed the Semaphore widget on your
            website.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className="api-key-modal-content">
          <div className="api-key-container">
            <span className="api-key">{showFullKey ? apiKey : maskedKey}</span>
            <IconButton className="icon-button" onClick={toggleShowFullKey}>
              {showFullKey ? <EyeOff size={20} /> : <Eye size={20} />}
            </IconButton>
            <IconButton className="icon-button" onClick={copyToClipboard}>
              <Copy size={20} />
            </IconButton>
          </div>
          {copied && <div className="copy-alert">Copied to clipboard!</div>}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default APIKeyModal;
