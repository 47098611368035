import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes, Navigate } from "react-router-dom";
import SemaphoreDashboard from "./components/Semaphore/SemaphoreDashboard";
import LoginPage from "./components/Login/LoginPage";
import RegisterPage from "./components/Register/RegisterPage";
import Gateway from "./components/Gateway";
import APIReference from "./components/APIReference";
import "./components/styles/App.css";
import AppFooter from "./components/AppFooter";
import ForgotPassword from "./components/Login/ForgotPassword";
import { isMobileDevice } from "./utils/mobileDetection";
import MobileMessage from "./components/MobileMessage";
import ResetPassword from "./components/Login/ResetPassword";
import ProtectedRoute from "./components/ProtectedRoute";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#8B008B",
    },
    background: {
      default: "#121212",
      paper: "#1E1E1E",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
});

const App: React.FC = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  if (isMobile) {
    return <MobileMessage />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Navigate to="/semaphore" replace />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/gateway"
          element={
            <ProtectedRoute>
              <Gateway />
            </ProtectedRoute>
          }
        />
        <Route
          path="/semaphore"
          element={
            <ProtectedRoute>
              <SemaphoreDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/api-reference"
          element={
            <ProtectedRoute>
              <APIReference />
            </ProtectedRoute>
          }
        />
      </Routes>
      <AppFooter />
    </ThemeProvider>
  );
};

export default App;
