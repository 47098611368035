import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import "./styles/UnsavedChangesAlert.css"; // Import the new CSS file

interface UnsavedChangesAlertProps {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
  onContinue: () => void;
}

const UnsavedChangesAlert: React.FC<UnsavedChangesAlertProps> = ({
  open,
  onClose,
  onDiscard,
  onContinue,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="unsaved-changes-dialog"
      PaperProps={{ className: "unsaved-changes-dialog" }}
      maxWidth={false}
    >
      <DialogTitle className="unsaved-changes-title">
        Unsaved Changes
      </DialogTitle>
      <DialogContent className="unsaved-changes-content">
        <Typography>
          You have unsaved changes. Are you sure you want to leave without
          saving?
        </Typography>
      </DialogContent>
      <DialogActions className="unsaved-changes-actions">
        <Button onClick={onDiscard} className="unsaved-changes-discard-button">
          Discard Changes
        </Button>
        <Button
          onClick={onContinue}
          className="unsaved-changes-continue-button"
          variant="contained"
        >
          Continue Editing
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangesAlert;
