import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./styles/DeleteWebsitePopup.css";
import axiosInstance from "../../axiosConfig";
import { useAtom, useSetAtom } from "jotai";
import { selectedWebsiteAtom, websitesAtom } from "../../state/atom";
import { Website } from "../../utils/types";

interface DeleteWebsitePopupProps {
  open: boolean;
  onClose: () => void;
  websiteID: number;
}

const DeleteWebsitePopup: React.FC<DeleteWebsitePopupProps> = ({
  open,
  onClose,
  websiteID,
}) => {
  const [websites, setWebsites] = useAtom(websitesAtom);
  const setSelectedSite = useSetAtom(selectedWebsiteAtom);
  const [confirmText, setConfirmText] = useState("");
  const [isDeleteEnabled, setIsDeleteEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const websiteName = websites.find(
    (website: Website) => website.id === websiteID
  )?.name;

  const confirmationStatement = `I confirm that I want to delete: "${websiteName}"`;

  useEffect(() => {
    setIsDeleteEnabled(confirmText.trim() === confirmationStatement);
  }, [confirmText, confirmationStatement]);

  const handleDeletePopupClose = () => {
    setConfirmText("");
    setError("");
    onClose();
  };

  const handleDelete = () => {
    if (isDeleteEnabled) {
      setLoading(true);
      axiosInstance
        .delete(`/websites/${websiteID}/`)
        .then(() => {
          setLoading(false);
          handleDeletePopupClose();
          let updatedWebsites = websites.filter(
            (website: Website) => website.id !== websiteID
          );
          setWebsites(updatedWebsites);
          setSelectedSite(null);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  };

  const handleConfirmTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmText(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleDeletePopupClose}
      className="delete-website-popup"
    >
      <DialogTitle className="delete-website-popup-title">
        Delete Website
        <CloseIcon onClick={handleDeletePopupClose} className="close-icon" />
      </DialogTitle>
      {error && (
        <div className="profile-error">
          <i className="error-icon">&#9888;</i>
          <p>{error}</p>
        </div>
      )}
      <DialogContent className="delete-website-popup-content">
        <DialogContentText className="delete-website-popup-text">
          Are you sure you want to delete <strong>{websiteName}</strong>? This
          action cannot be undone. To confirm, please type the following
          statement:
        </DialogContentText>
        <DialogContentText className="delete-website-popup-confirmation">
          {confirmationStatement}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          variant="outlined"
          value={confirmText}
          onChange={handleConfirmTextChange}
          placeholder="Type the confirmation statement here"
          className="delete-website-popup-input"
        />
      </DialogContent>
      <DialogActions className="delete-website-popup-actions">
        <Button onClick={onClose} disabled={loading} className="cancel-button">
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          disabled={!isDeleteEnabled || loading}
          className="delete-button"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteWebsitePopup;
