import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./styles/WarningPopup.css";

interface WarningPopupProps {
  message: string;
  isOpen: boolean;
  onClose: () => void;
}

const WarningPopup: React.FC<WarningPopupProps> = ({
  message,
  isOpen,
  onClose,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        className: "warning-popup-modal-dialog",
      }}
    >
      <DialogTitle className="warning-popup-modal-title">
        <Typography
          variant="h6"
          component="div"
          className="warning-popup-modal-heading"
        >
          Error
        </Typography>
        <IconButton
          onClick={onClose}
          size="small"
          className="warning-popup-modal-close-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="warning-popup-modal-content">
        <Typography className="warning-message">{message}</Typography>
        <Button className="warning-popup-button" onClick={onClose}>
          OK
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default WarningPopup;
