import "./styles/MobileMessage.css";

const MobileMessage = () => {
  return (
    <div className="mobile-message-container">
      <div className="logo">
        <img src="logo_color_white.png" alt="Anybyte" />
        <h1>Anybyte</h1>
      </div>

      <h1>You've ventured into the unexplored.</h1>

      <p>
        Mobile isn't available yet.
        <br />
        Visit the site on desktop
        <br />
        for the best experience.
      </p>

      <div className="background">
        <img src="space_background.png" alt="Space Background" />
      </div>
    </div>
  );
};

export default MobileMessage;
